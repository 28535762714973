.carousel {
  position: relative;
}

.carousel__content {
  position: relative;
}

.carousel__image-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  cursor: grab;
}

.carousel__image-wrapper--dragging {
  cursor: inherit;
}

.carousel__canvas-wrapper {
  position: relative;
  grid-column: 1;
  grid-row: 1;
  user-select: none;
}

.carousel__canvas {
  position: relative;
  display: block;
}

.carousel__placeholder {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  transition: opacity 1s ease;
  opacity: 1;
  pointer-events: none;
  position: relative;
  z-index: 1;
}

.carousel__placeholder--loaded {
  opacity: 0;
}

.carousel__button {
  border: 0;
  margin: 0;
  background: none;
  padding: var(--spaceM) var(--spaceL);
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 32;
  cursor: pointer;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
  }

  &:active {
    box-shadow: none;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    transition-property: background, box-shadow;
    transition-duration: var(--durationM);
    transition-timing-function: var(--bezierFastoutSlowin);
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 12px),
      calc(100% - 12px) 100%,
      0 100%
    );
  }

  &:hover::before {
    background: rgb(var(--rgbBlack) / 0.2);
  }

  & svg {
    fill: rgb(var(--rgbBlack) / 0.5);
    display: block;
  }

  @media (--mediaMobile) {
    & {
      display: none;
    }
  }
}

.carousel__button--prev {
  left: var(--spaceM);
}

.carousel__button--next {
  right: var(--spaceM);
}

.carousel__nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spaceS);
}

.carousel__nav-button {
  --navButtonSize: 10px;
  --buttonColor: rgb(var(--rgbText) / 0.2);

  background: none;
  border: 0;
  margin: 0;
  padding: var(--spaceM);
  cursor: pointer;
  outline: none;

  &[aria-pressed='true'] {
    --buttonColor: var(--colorTextBody);
  }

  &::after {
    content: '';
    border-radius: 50%;
    display: block;
    width: var(--navButtonSize);
    height: var(--navButtonSize);
    background: var(--buttonColor);
    transition-property: background, box-shadow;
    transition-duration: var(--durationL);
    transition-timing-function: var(--bezierFastoutSlowin);
  }

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
  }

  &:active {
    box-shadow: none;
  }
}
